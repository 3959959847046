import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';
import { ThemeMode } from 'types/config';

const chipSX = {
    height: 24,
    lineHeight: 1,
    padding: '0 6px',
};

export const ChipErrorStyles = styled(Chip)(({ theme }) => ({
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    ...theme.applyStyles(ThemeMode.DARK, {
        backgroundColor: theme.palette.dark.main,
    }),
    marginRight: '5px',
}));

export const ChipWarningStyles = styled(Chip)(({ theme }) => ({
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
    ...theme.applyStyles(ThemeMode.DARK, {
        backgroundColor: theme.palette.dark.main,
    }),
}));

export const ChipSuccessStyles = styled(Chip)(({ theme }) => ({
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    ...theme.applyStyles(ThemeMode.DARK, {
        backgroundColor: theme.palette.dark.main,
    }),
}));
