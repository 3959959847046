import dayjs from 'dayjs';
import { GROUP_RIDINGS } from './consts';
import CHANGELOG from '../../CHANGELOG.md';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '../config/consts';

export const extractLatestVersion = () => {
    const versionRegex = /## \[(\d+\.\d+\.\d+)\]/g;
    let match;
    const versions = [];

    while ((match = versionRegex.exec(CHANGELOG)) !== null) {
        versions.push(match[1]);
    }

    versions.sort((a, b) => {
        const aParts = a.split('.').map(Number);
        const bParts = b.split('.').map(Number);

        for (let i = 0; i < 3; i++) {
            if (aParts[i] > bParts[i]) return -1;
            if (aParts[i] < bParts[i]) return 1;
        }
        return 0;
    });

    return versions[0];
};

export const isProduction = () => {
    return import.meta.env.PROD;
};

export const Cookie = {
    tld: true, // if true, set cookie domain at top level domain
    set: function (name: string, value: string, days?: number) {
        const cookie: Record<string, unknown> = { [name]: value, path: '/' };

        if (days) {
            const date: Date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            cookie.expires = date.toUTCString();
        }

        if (Cookie.tld) {
            cookie.domain = '.' + window.location.hostname.split('.').slice(-2).join('.');
        }

        const arr = [];
        for (const key in cookie) {
            arr.push(`${key}=${cookie[key]}`);
        }
        document.cookie = arr.join('; ');

        return this.get(name);
    },

    getAll: function () {
        const cookie = {};
        document.cookie.split(';').forEach((el) => {
            const [k, v] = el.split('=');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cookie[k.trim()] = v;
        });
        return cookie;
    },

    get: function (name: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.getAll()[name];
    },

    delete: function (name: string) {
        this.set(name, '', -1);
    },
};

export const fixNumber = (number: number): string => {
    if (number < 10) {
        return `0${number}`;
    }

    return number.toString();
};

export const formatPhoneNumber = (phoneNumber: number | string): string => {
    const number = String(phoneNumber).replace(/\D/g, '');

    if (number.length < 9) {
        return number;
    }

    if (number.length === 11) {
        return `+${number.slice(0, 2)} ${number.slice(2, 5)} ${number.slice(5, 8)} ${number.slice(8)}`;
    }

    const groupNumber = number.match(/^(\d{3})(\d{3})(\d{3})$/);

    return groupNumber ? groupNumber.slice(1).join(' ') : number;
};

export const formatNumberToTime = (number: number, version: 'long' | 'short' = 'long'): string => {
    const hours = Math.floor(number / 60);
    const minutes = number % 60;

    if (version === 'short') {
        return `${fixNumber(hours)}:${fixNumber(minutes)}`;
    }

    return `${hours} godz. ${fixNumber(minutes)} min.`;
};

export const isPageActive = (page: string): boolean => {
    return window.location.href.includes(page);
};

export const navigateHistoryBack = () => {
    window.history.back();
};

export const formatDate = (date: Date | string): string => {
    return dayjs(date).format(DATE_FORMAT);
};

export const fromToDateFormat = (from: Date, to: Date): string => {
    if (dayjs(from).isSame(to, 'day')) {
        return `${dayjs(from).format(DATE_FORMAT)} ${dayjs(from).format(TIME_FORMAT)} - ${dayjs(to).format(TIME_FORMAT)}`;
    }

    return `${dayjs(from).format(DATE_TIME_FORMAT)} - ${dayjs(to).format(DATE_TIME_FORMAT)}`;
};

export const isGroupRiding = (type: number): boolean => {
    return GROUP_RIDINGS.includes(type);
};

export const adjustBrightness = (hexColor: string, percent: number): string => {
    const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
        // Usunięcie znaku '#' jeśli jest obecny
        hex = hex.replace('#', '');

        // Konwersja 3-znakowego HEX na 6-znakowy
        if (hex.length === 3) {
            hex = hex
                .split('')
                .map((h) => h + h)
                .join('');
        }

        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return { r, g, b };
    };

    const rgbToHsl = (r: number, g: number, b: number): { h: number; s: number; l: number } => {
        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        let h = 0,
            s = 0;
        const l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatyczny
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return { h, s, l };
    };

    const hslToRgb = (h: number, s: number, l: number): { r: number; g: number; b: number } => {
        let r: number, g: number, b: number;

        if (s === 0) {
            r = g = b = l; // achromatyczny
        } else {
            const hue2rgb = (p: number, q: number, t: number): number => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return { r: r * 255, g: g * 255, b: b * 255 };
    };

    const rgbToHex = (r: number, g: number, b: number): string => {
        r = Math.round(r);
        g = Math.round(g);
        b = Math.round(b);
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    };

    // Konwersja HEX na RGB
    const { r, g, b } = hexToRgb(hexColor);

    // Konwersja RGB na HSL
    // eslint-disable-next-line prefer-const
    let { h, s, l } = rgbToHsl(r, g, b);

    // Modyfikacja jasności
    l *= (100 + percent) / 100;
    l = Math.min(1, Math.max(0, l));

    // Konwersja HSL z powrotem na RGB
    const { r: newR, g: newG, b: newB } = hslToRgb(h, s, l);

    // Konwersja RGB na HEX
    return rgbToHex(newR, newG, newB);
};

export const formatPrice = (price?: number): string => {
    if (!price) {
        return '0,00 zł';
    }

    if (price) {
        price = price / 100;
    }

    return `${price.toFixed(2).replace('.', ',')} zł`;
};
