import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazyPaymentsList = Loadable(lazy(() => import('views/pages/Payments/List')));

const Payments = {
    path: '/payments',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/payments',
            element: <LazyPaymentsList />,
        },
    ],
};

export default Payments;
