const ERROR_CODES: Record<number, string> = {
    100: 'Adres email lub hasło są nieprawidłowe.',
    103: 'Adres email jest już zajęty.',
    104: 'Stajnia o podanym adresie email widnieje już w naszej bazie danych.',

    301: 'Rozmiar pliku nie może przekraczać 7MB',
    302: 'Plik musi być w formacie JPG lub PNG',

    400: 'Wyczerpano dostępną ilość kont pracowników w wybranym planie subskrypcji. W każdej chwili możesz zwiększyć swój limit w Ustawieniach > Plan i rozliczenie.',

    600: 'Aktualinie do Twojej stajni są przypisane aktywne subskrypcje. Aby usunąć stajnię, musisz najpierw anulować subskrypcję.',

    700: 'Nie mogłem dodać SMSa do kolejki wysyłki. Spróbuj ponownie później. Jeżeli problem się powtarza, skontaktuj się z nami.',
    701: 'Nie mogłem wysłać SMSa. Spróbuj ponownie później. Jeżeli problem się powtarza, skontaktuj się z nami.',
    702: 'Brak wystarczającej liczby SMSów w pakiecie. Aby wysłać SMSa, musisz zwiększyć swój limit w Ustawieniach > Plan i rozliczenie.',
};

export default ERROR_CODES;
