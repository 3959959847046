import { MessageEntity } from 'entities/MessageEntity';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UnreadType = {
    all: number;
    stable: number;
    app: number;
};

interface MailSlice {
    messages: Array<MessageEntity>;
    unread: UnreadType;
}

const initialState: MailSlice = {
    messages: [],
    unread: {
        all: 0,
        stable: 0,
        app: 0,
    },
};

export const mailSlice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        setMessages: (state, action: PayloadAction<Array<MessageEntity>>) => {
            state.messages = action.payload;
        },
        setUnread: (state, action: PayloadAction<UnreadType>) => {
            state.unread = action.payload;
        },
        setMessageReadStatus: (state, action: PayloadAction<{ id: string; isRead: boolean }>) => {
            const { id, isRead } = action.payload;

            const message = state.messages.find((message) => message.id === id);

            if (message) {
                message.isRead = isRead;
            }
        },
        updateUnread: (state) => {
            const messages = state.messages;
            const unread = {
                all: 0,
                stable: 0,
                app: 0,
            };

            messages.forEach((message) => {
                if (!message.isRead) {
                    unread.all++;
                    unread[message.type]++;
                }
            });

            state.unread = unread;
        },
    },
});

export const { setMessages, setUnread, setMessageReadStatus, updateUnread } = mailSlice.actions;

export default mailSlice.reducer;
