import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HorseEntity } from 'entities/HorseEntity';

interface HorsesSlice {
    list: Array<HorseEntity> | null;
}

const initialState: HorsesSlice = {
    list: null,
};

export const horsesSlice = createSlice({
    name: 'horses',
    initialState,
    reducers: {
        setHorses: (state, action: PayloadAction<Array<HorseEntity> | null>) => {
            state.list = action.payload;
        },
    },
});

export const { setHorses } = horsesSlice.actions;

export default horsesSlice.reducer;
