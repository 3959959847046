import { IconCalendar, IconCalendarMonth, IconDashboard, IconMail } from '@tabler/icons-react';
import { __ } from 'misc/i18n';
import { NavItemType } from 'types';

const icons = {
    IconDashboard,
    IconMail,
    IconCalendar,
    IconCalendarMonth,
};

const dashboard: NavItemType = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: __('Panel'),
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
        },
        {
            id: 'schedule',
            title: 'Plan dnia',
            type: 'item',
            url: '/schedule',
            icon: icons.IconCalendar,
            breadcrumbs: false,
        },
        {
            id: 'weekly-calendar',
            title: 'Plan tygodniowy',
            type: 'item',
            url: '/weekly-calendar',
            icon: icons.IconCalendarMonth,
            breadcrumbs: false,
        },
        {
            id: 'mail',
            title: 'Wiadomości',
            type: 'item',
            url: '/mail/all',
            icon: icons.IconMail,
            breadcrumbs: false,
        },
    ],
};

export default dashboard;
