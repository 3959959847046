// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
// import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
// import LocalizationSection from './LocalizationSection';
import FullScreenSection from './FullScreenSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons-react';

// types
import { MenuOrientation, ThemeMode } from 'types/config';
import Helpdesk from './Helpdesk';
import { useDispatch, useSelector } from 'store';
import { setDrawerOpenState } from 'store/slices/config.slice';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const downMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { mode, menuOrientation } = useConfig();
    const drawerOpen = useSelector((state) => state.config.drawerOpen);
    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downMD;

    return (
        <>
            {/* logo & toggler button */}
            <Box sx={{ width: downMD ? 'auto' : 228, display: 'flex' }}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {!isHorizontal && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'secondary.light',
                            color: mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                            '&:hover': {
                                bgcolor: mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                                color: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.light',
                            },
                        }}
                        onClick={() => dispatch(setDrawerOpenState(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            <Helpdesk />

            {/* notification */}
            <NotificationSection />

            {/* full sceen toggler */}
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <FullScreenSection />
            </Box>

            {/* profile */}
            <Box sx={{ flexGrow: 0, marginLeft: 3 }}>
                <ProfileSection />
            </Box>

            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};

export default Header;
