import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazyMail = Loadable(lazy(() => import('views/pages/Mail')));

const Mail = {
    path: '/mail',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/mail/:type/:id?',
            element: <LazyMail />,
        },
    ],
};

export default Mail;
