import { createBrowserRouter, Navigate } from 'react-router-dom';

import AuthenticationRoutes from './AuthenticationRoutes';
import Dashboard from './Dashboard.tsx';
import Users from './Users.tsx';
import Horses from './Horses.tsx';
import RidingSpots from './RidingSpots.tsx';
import Clients from './Clients.tsx';
import Schedule from './Schedule.tsx';
import Mail from './Mail.tsx';
import Settings from './Settings.tsx';
import Payments from './Payments.tsx';
import HorseRiding from './HorseRiding.tsx';
import SMSReports from './SMSReports.tsx';
import Others from './Others.tsx';
import WeeklyCalendar from './WeeklyCalendar.tsx';

// ==============================|| ROUTING RENDER ||============================== //
const router = createBrowserRouter(
    [
        { path: '/', element: <Navigate to={'dashboard'} /> },
        AuthenticationRoutes,
        Dashboard,
        Users,
        Horses,
        RidingSpots,
        Clients,
        Schedule,
        Mail,
        Settings,
        Payments,
        HorseRiding,
        SMSReports,
        Others,
        WeeklyCalendar,
        {
            path: '/*',
            element: <div>404 - taka strona nie istnieje</div>,
        },
    ],
    {
        basename: import.meta.env.VITE_APP_BASE_NAME,
    },
);

export default router;
