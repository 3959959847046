import { __ } from 'misc/i18n';
import { ACTIONS } from 'controllers/Permissions/consts';
import { IconCarambola, IconCreditCard, IconDeviceMobileMessage, IconSettings } from '@tabler/icons-react';
import { NavItemType } from 'types';

const icons = {
    IconDeviceMobileMessage,
    IconCreditCard,
    IconCarambola,
    IconSettings,
};

const settings: NavItemType = {
    id: 'group-administration',
    title: __('Administracja'),
    type: 'group',
    permission: ACTIONS.UPDATE_STABLE,
    children: [
        {
            id: 'payments',
            title: __('Płatności'),
            type: 'item',
            url: '/payments',
            icon: icons.IconCreditCard,
            breadcrumbs: false,
            permission: ACTIONS.UPDATE_STABLE,
        },
        {
            id: 'sms-reports',
            title: __('Raporty Wysyłki SMS'),
            type: 'item',
            url: '/sms-reports',
            icon: icons.IconDeviceMobileMessage,
            breadcrumbs: false,
            permission: ACTIONS.UPDATE_STABLE,
        },
        {
            id: 'billing',
            title: __('Subskrypcje'),
            type: 'item',
            url: '/settings/billing',
            icon: icons.IconCarambola,
            breadcrumbs: false,
            permission: ACTIONS.MENAGE_SUBSCRIPTION,
        },
        {
            id: 'settings',
            title: __('Ustawienia'),
            type: 'collapse',
            icon: icons.IconSettings,
            breadcrumbs: false,
            permission: ACTIONS.UPDATE_STABLE,
            children: [
                {
                    id: 'stable',
                    title: __('Stajnia'),
                    type: 'item',
                    url: '/settings/stable',
                    // icon: icons.SettingOutlined,
                    breadcrumbs: false,
                    permission: ACTIONS.UPDATE_STABLE,
                },
                {
                    id: 'sms-settings',
                    title: __('Wysyłka SMS'),
                    type: 'item',
                    url: '/settings/sms',
                    // icon: icons.IconMessage,
                    breadcrumbs: false,
                    permission: ACTIONS.UPDATE_STABLE,
                },
                {
                    id: 'event-types',
                    title: __('Typy wydarzeń'),
                    type: 'item',
                    url: '/settings/event-types',
                    // icon: icons.IconSettingsDollar,
                    breadcrumbs: false,
                    permission: ACTIONS.UPDATE_STABLE,
                },
                {
                    id: 'permissions',
                    title: __('Uprawnienia'),
                    type: 'item',
                    url: '/settings/permissions',
                    // icon: icons.SafetyOutlined,
                    breadcrumbs: false,
                    permission: ACTIONS.UPDATE_STABLE,
                },
            ],
        },
    ],
};

export default settings;
