import { Box, Chip } from '@mui/material';
import { extractLatestVersion } from 'misc/helpers';

const AppVersion = () => {
    return (
        <Box mt={1}>
            <Chip
                label={'v' + extractLatestVersion()}
                size="small"
                sx={{
                    height: 18,
                    '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 },
                }}
                component="span"
            />
        </Box>
    );
};

export default AppVersion;
