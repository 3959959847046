import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientEntity } from 'entities/ClientEntity';

interface ClientsSlice {
    list: Array<ClientEntity> | null;
}

const initialState: ClientsSlice = {
    list: null,
};

export const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setClients: (state, action: PayloadAction<Array<ClientEntity> | null>) => {
            state.list = action.payload;
        },
    },
});

export const { setClients } = clientsSlice.actions;

export default clientsSlice.reducer;
