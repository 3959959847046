import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazyRidingSpotsList = Loadable(lazy(() => import('views/pages/RidingSpots/List')));
const LazyEditRidingSpot = Loadable(lazy(() => import('views/pages/RidingSpots/Edit')));

const RidingSpots = {
    path: '/riding-spots',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/riding-spots',
            element: <LazyRidingSpotsList />,
        },
        {
            path: '/riding-spots/edit',
            element: <LazyEditRidingSpot />,
        },
        {
            path: '/riding-spots/edit/:id',
            element: <LazyEditRidingSpot />,
        },
    ],
};

export default RidingSpots;
