import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazySMSReportsList = Loadable(lazy(() => import('views/pages/SMSReports/List')));

const SMSReports = {
    path: '/sms-reports',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),

    children: [
        {
            path: '/sms-reports',
            element: <LazySMSReportsList />,
        },
    ],
};

export default SMSReports;
