import { IconAddressBook, IconHorse } from '@tabler/icons-react';
import { NavItemType } from 'types';

const icons = {
    IconHorse,
    IconAddressBook,
};

const client: NavItemType = {
    id: 'group-client',
    title: 'Klienci',
    type: 'group',
    children: [
        {
            id: 'clients',
            title: 'Klienci',
            type: 'item',
            url: '/clients',
            icon: icons.IconAddressBook,
            breadcrumbs: false,
        },
        {
            id: 'horse-riding',
            title: 'Lista jazd konnych',
            type: 'item',
            url: '/horse-riding',
            icon: icons.IconHorse,
            breadcrumbs: false,
        },
    ],
};

export default client;
