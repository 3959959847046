import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'ui-component/RequireAuth';

const LazyHorsesList = Loadable(lazy(() => import('views/pages/Other/Contact')));

const Others = {
    path: '/',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/contact',
            element: <LazyHorsesList />,
        },
    ],
};

export default Others;
