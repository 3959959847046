// third-party
import { combineReducers } from 'redux';
import UserReducer from 'store/slices/user.slice';
import ConfigReducer from 'store/slices/config.slice';
import StableReducer from 'store/slices/stable.slice';
import RidingSpotsReducer from 'store/slices/ridingSpots.slice';
import ClientsReducer from 'store/slices/clients.slice';
import HorsesReducer from 'store/slices/horses.slice.ts';
import MailReducer from 'store/slices/mail.slice.ts';
import DashboardReducer from 'store/slices/dashboard.slice';
import SubscriptionSlice from 'store/slices/subscription.slice';
import SchedulesReducer from 'store/slices/schedules.slice';
import SnackbarReducer from 'store/slices/snackbar.slice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    dashboard: DashboardReducer,
    stable: StableReducer,
    user: UserReducer,
    config: ConfigReducer,
    ridingSpots: RidingSpotsReducer,
    clients: ClientsReducer,
    horses: HorsesReducer,
    mail: MailReducer,
    subscription: SubscriptionSlice,
    schedules: SchedulesReducer,
    snackbar: SnackbarReducer,
});

export default reducer;
