import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import HorizontalBar from './HorizontalBar';
import MainContentStyled from './MainContentStyled';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { setDrawerOpenState } from 'store/slices/config.slice';

// types
import { MenuOrientation } from 'types/config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const downMD = useMediaQuery(theme.breakpoints.down('md'));

    const { borderRadius, container, miniDrawer, menuOrientation } = useConfig();
    const drawerOpen = useSelector((state) => state.config.drawerOpen);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDrawerOpenState(!miniDrawer));
    }, [miniDrawer]);

    useEffect(() => {
        downMD && dispatch(setDrawerOpenState(false));
    }, [downMD]);

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downMD;

    // horizontal menu-list bar : drawer
    const menu = useMemo(() => (isHorizontal ? <HorizontalBar /> : <Sidebar />), [isHorizontal]);

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{ bgcolor: 'background.default' }}
            >
                <Toolbar sx={{ p: isHorizontal ? 1.25 : 2 }}>
                    <Header />
                </Toolbar>
            </AppBar>

            {/* menu / drawer */}
            {menu}

            {/* main content */}
            <MainContentStyled {...{ borderRadius, menuOrientation, open: drawerOpen }}>
                <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                    {/* breadcrumb */}
                    <Breadcrumbs />
                    <Outlet />
                </Container>
            </MainContentStyled>
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;
