import { StableEntity } from 'entities/StableEntity';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StableSlice {
    stable: StableEntity | null;
}

const initialState: StableSlice = {
    stable: null,
};

export const stableSlice = createSlice({
    name: 'stable',
    initialState,
    reducers: {
        setStable: (state, action: PayloadAction<StableEntity | null>) => {
            state.stable = action.payload;
        },
    },
});

export const { setStable } = stableSlice.actions;

export default stableSlice.reducer;
