import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazyHorsesList = Loadable(lazy(() => import('views/pages/Horses/List')));
const LazyEditHorse = Loadable(lazy(() => import('views/pages/Horses/Edit')));
const LazyHorseProfile = Loadable(lazy(() => import('views/pages/Horses/Details')));

const Horses = {
    path: '/horses',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/horses',
            element: <LazyHorsesList />,
        },
        {
            path: '/horses/edit',
            element: <LazyEditHorse />,
        },
        {
            path: '/horses/edit/:id',
            element: <LazyEditHorse />,
        },
        {
            path: '/horses/profile/:id',
            element: <LazyHorseProfile />,
        },
    ],
};

export default Horses;
