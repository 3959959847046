import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazyClientsList = Loadable(lazy(() => import('views/pages/Clients/List')));
const LazyEditClient = Loadable(lazy(() => import('views/pages/Clients/Edit')));
const LazyClientProfile = Loadable(lazy(() => import('views/pages/Clients/Profile')));

const Clients = {
    path: '/clients',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/clients',
            element: <LazyClientsList />,
        },
        {
            path: '/clients/edit',
            element: <LazyEditClient />,
        },
        {
            path: '/clients/edit/:id',
            element: <LazyEditClient />,
        },
        {
            path: '/clients/profile/:id',
            element: <LazyClientProfile />,
        },
    ],
};

export default Clients;
