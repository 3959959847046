import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazyPanel = Loadable(lazy(() => import('views/pages/Panel')));

const Dashboard = {
    path: '/dashboard',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/dashboard',
            element: (
                <>
                    <LazyPanel />
                </>
            ),
        },
    ],
};

export default Dashboard;
