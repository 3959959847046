import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { ThemeMode } from 'types/config';

export const AvatarSecondaryStyles = styled(Avatar)(({ theme }) => ({
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    ...theme.applyStyles(ThemeMode.DARK, {
        background: theme.palette.dark.main,
        color: theme.palette.warning.dark,
    }),
    '&[aria-controls="menu-list-grow"],&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light,

        ...theme.applyStyles(ThemeMode.DARK, {
            background: theme.palette.warning.dark,
            color: theme.palette.grey[800],
        }),
    },
}));
