import dashboard from './dashboard';
import stable from './stable';
import client from './client';
import settings from './settings';
import other from './other';

import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, stable, client, settings, other],
};

export default menuItems;
