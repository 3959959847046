import { UserEntity } from '../entities/UserEntity.ts';
import axios, { AxiosRequestConfig } from 'axios';
import { StableEntity } from '../entities/StableEntity.ts';
import { ActiveUsersHorsesEntity } from '../entities/ActiveUsersHorsesEntity.ts';

export const addStableService = (formData: Partial<UserEntity>) => {
    return axios.post<UserEntity>(`/stables`, formData);
};

export const getStableByID = (stableID: string, options?: AxiosRequestConfig) => {
    return axios.get<StableEntity>(`/stables/${stableID}`, options);
};

export const updateStable = (stableData: FormData) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    return axios.patch('/stables', stableData, config);
};

export const getUsersHorsesCountService = (options?: AxiosRequestConfig) => {
    return axios.get<ActiveUsersHorsesEntity>('/stables/users-horses-count', options);
};

export const deleteStable = () => {
    return axios.delete('/stables');
};

export const sendTestSMSService = (message: string) => {
    return axios.post('/stables/send-test-sms', { message });
};
