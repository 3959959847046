import { Box, Typography } from '@mui/material';
import { DASHBOARD_PATH } from 'config';
import { API_URL } from 'config/consts';
import { Link } from 'react-router';
import { useSelector } from 'store';

interface Props {
    justify?: 'center' | 'end' | 'start';
    src?: string | null;
}

const Logo = ({ justify = 'start', src }: Props) => {
    const { stable } = useSelector((state) => state.stable);
    const stableName = stable?.name;
    let logo = null;

    if (src) {
        logo = src;
    } else if (stable?.logo) {
        logo = API_URL + stable.logo;
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '48px', mr: 2 }}>
            <Link to={DASHBOARD_PATH}>
                {logo ? (
                    <img
                        src={logo}
                        alt={stableName}
                        style={{ maxWidth: 170, maxHeight: 48, margin: 0, display: 'block' }}
                        crossOrigin="anonymous"
                    />
                ) : (
                    <Typography
                        variant="h2"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'flex' },
                            flexGrow: 0,
                            fontWeight: 700,
                            letterSpacing: '0.1rem',
                            color: 'grey.700',
                            textDecoration: 'none',
                            justifyContent: justify,
                            textShadow: '3px 3px 0px rgba(0, 0, 0, 0.1)',
                            lineHeight: 1,
                            py: 1.5,
                        }}
                    >
                        {stableName || 'NaszaStajnia'}
                    </Typography>
                )}
            </Link>
        </Box>
    );
};

export default Logo;
