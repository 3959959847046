import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import RequireAuth from 'ui-component/RequireAuth';
import MainLayout from 'layout/MainLayout';

const LazySettingsBilling = Loadable(lazy(() => import('views/pages/Settings/Billing')));
const LazySettingsPlanUpgrade = Loadable(lazy(() => import('views/pages/Settings/Billing/Upgrade')));
const LazySettingsStable = Loadable(lazy(() => import('views/pages/Settings/Stable')));
const LazySettingsPermissions = Loadable(lazy(() => import('views/pages/Settings/Permissions')));
const LazySettingsEventTypes = Loadable(lazy(() => import('views/pages/Settings/EventTypes/List')));
const LazySettingsEventTypesEdit = Loadable(lazy(() => import('views/pages/Settings/EventTypes/Edit')));
const LazySettingsSMS = Loadable(lazy(() => import('views/pages/Settings/SMS')));

const Settings = {
    path: '/settings',
    element: (
        <RequireAuth>
            <MainLayout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/settings/billing',
            element: <LazySettingsBilling />,
        },
        {
            path: '/settings/billing/upgrade',
            element: <LazySettingsPlanUpgrade />,
        },
        {
            path: '/settings/stable',
            element: <LazySettingsStable />,
        },
        {
            path: '/settings/permissions',
            element: <LazySettingsPermissions />,
        },
        {
            path: '/settings/event-types',
            element: <LazySettingsEventTypes />,
        },
        {
            path: '/settings/event-types/edit',
            element: <LazySettingsEventTypesEdit />,
        },
        {
            path: '/settings/event-types/edit/:id',
            element: <LazySettingsEventTypesEdit />,
        },
        {
            path: '/settings/sms',
            element: <LazySettingsSMS />,
        },
    ],
};

export default Settings;
