import { IconLifebuoyFilled } from '@tabler/icons-react';
import { __ } from 'misc/i18n';
import { NavItemType } from 'types';

const other: NavItemType = {
    id: 'group-other',
    title: '',
    type: 'group',
    children: [
        {
            id: 'contact',
            title: __('Kontakt'),
            type: 'item',
            url: '/contact',
            icon: IconLifebuoyFilled,
            breadcrumbs: false,
        },
    ],
};

export default other;
