import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RidingSpotEntity } from 'entities/RidingSpotEntity';

interface RidingSpotSlice {
    spots: Array<RidingSpotEntity> | null;
}

const initialState: RidingSpotSlice = {
    spots: null,
};

export const ridingSpotSlice = createSlice({
    name: 'ridingSpot',
    initialState,
    reducers: {
        setRidingSpots: (state, action: PayloadAction<Array<RidingSpotEntity> | null>) => {
            state.spots = action.payload;
        },
    },
});

export const { setRidingSpots } = ridingSpotSlice.actions;

export default ridingSpotSlice.reducer;
