export const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.nasza-stajnia.pl' : 'http://localhost:3000';

export const SUBSCRIPTION_FREE_PLAN = {
    horseLimit: 999,
    userLimit: 999,
};

export const DATE_FORMAT = 'DD.MM.YYYY';

export const TIME_FORMAT = 'HH:mm';

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const SUBSCRIPTION_NOT_ACTIVE_STATUSES = ['future', 'paused', 'cancelled', 'transferred'];

export const RIDING_STATUS_COLORS = {
    done: '#4CAF50',
    cancelled_client: '#FFC107',
    cancelled_stable: '#F44336',
};

export const RIDING_STATUS_NAMES = {
    done: 'Odbyła się',
    cancelled_client: 'Odwołana przez klienta',
    cancelled_stable: 'Odwołana przez stajnię',
};

export const SMS_REPORT_STATUS_NAMES = {
    sent: 'Wysłany',
    failed: 'Błąd',
    queued: 'W kolejce',
};

export const SMS_REPORT_STATUS_COLORS = {
    sent: 'success',
    failed: 'error',
    queued: 'info',
};

export const SMS_MESSAGE_MAX_LENGTH = 70;
