import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const LazyLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const LazyRegister = Loadable(lazy(() => import('views/pages/authentication/Register')));
const LazyRegisterDone = Loadable(lazy(() => import('views/pages/authentication/RegisterDone')));
const LazyVerifyEmail = Loadable(lazy(() => import('views/pages/authentication/VerifyEmail')));
const LazyForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword')));
const LazyResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword')));
const LazyInvitation = Loadable(lazy(() => import('views/pages/authentication/Invitation')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <LazyLogin />,
        },
        {
            path: '/register',
            element: <LazyRegister />,
        },
        {
            path: '/register/:token',
            element: <LazyRegister />,
        },
        {
            path: '/register-done',
            element: <LazyRegisterDone />,
        },
        {
            path: '/verify-email/:token',
            element: <LazyVerifyEmail />,
        },
        {
            path: '/forgot-password',
            element: <LazyForgotPassword />,
        },
        {
            path: '/reset-password/:token',
            element: <LazyResetPassword />,
        },
        {
            path: '/invitation/:token',
            element: <LazyInvitation />,
        },
    ],
};

export default AuthenticationRoutes;
