import { IconHorse, IconPennant, IconUser } from '@tabler/icons-react';
import { NavItemType } from 'types';

const icons = {
    IconUser,
    IconHorse,
    IconPennant,
};

const stable: NavItemType = {
    id: 'group-stable',
    title: 'Stajnia',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Pracownicy',
            type: 'item',
            url: '/users',
            icon: icons.IconUser,
            breadcrumbs: false,
        },
        {
            id: 'horses',
            title: 'Nasze Konie',
            type: 'item',
            url: '/horses',
            icon: icons.IconHorse,
            breadcrumbs: false,
        },
        {
            id: 'riding-spots',
            title: 'Miejsca Jazd',
            type: 'item',
            url: '/riding-spots',
            icon: icons.IconPennant,
            breadcrumbs: false,
        },
    ],
};

export default stable;
