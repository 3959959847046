import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PermissionsSectionType } from '../../misc/types.ts';

export const usePermissionsGuard = () => {
    const permissions = useSelector((state: RootState) => state.stable.stable?.permissions);
    const user = useSelector((state: RootState) => state.user.user);

    const checkPermission = (action: string) => {
        let canDisplay = false;

        if (!user || !permissions) {
            return canDisplay;
        }

        Object.keys(permissions).map((section) => {
            const sectionPermissions = permissions[section as unknown as PermissionsSectionType];

            sectionPermissions[action]?.role.map((permission) => {
                if (user.role.includes(permission)) {
                    canDisplay = true;
                }

                return permission;
            });
        });

        return canDisplay;
    };

    return { checkPermission };
};
