import axios from 'axios';
import { Cookie } from 'misc/helpers';

const token = Cookie.get('kkt');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL || 'https://api.nasza-stajnia.pl/v1';
axios.defaults.withCredentials = false;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
